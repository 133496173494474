import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
// import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

// const icon = (name) => (
//   <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
//   // OR
//   // <Iconify icon="fluent:mail-24-filled" />
//   // https://icon-sets.iconify.design/solar/
//   // https://www.streamlinehq.com/icons
// );

// const ICONS = {
//   home: icon('home'),
//   games: icon('games'),
//   players: icon('players'),
//   branches: icon('branches'),
//   categories: icon('categories'),
//   news: icon('news'),
//   job: icon('ic_job'),
//   blog: icon('ic_blog'),
//   chat: icon('ic_chat'),
//   mail: icon('ic_mail'),
//   user: icon('ic_user'),
//   file: icon('ic_file'),
//   lock: icon('ic_lock'),
//   tour: icon('ic_tour'),
//   order: icon('ic_order'),
//   label: icon('ic_label'),
//   blank: icon('ic_blank'),
//   kanban: icon('ic_kanban'),
//   folder: icon('ic_folder'),
//   banking: icon('ic_banking'),
//   booking: icon('ic_booking'),
//   invoice: icon('ic_invoice'),
//   product: icon('ic_product'),
//   calendar: icon('ic_calendar'),
//   disabled: icon('ic_disabled'),
//   external: icon('ic_external'),
//   menuItem: icon('ic_menu_item'),
//   ecommerce: icon('ic_ecommerce'),
//   analytics: icon('ic_analytics'),
//   dashboard: icon('ic_dashboard'),
// };

// ----------------------------------------------------------------------

export function useNavData() {
  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: 'General',
        items: [
          { title: 'Etkinlikler', path: paths.dashboard.root },
          { title: 'Analizler', path: paths.dashboard.analyses },

          // {
          //   title: 'Liderlik Tablosu',
          //   path: paths.dashboard.leaderboard,
          // },
        ],
      },

      //       {
      //   subheader: 'Kullanıcılar',
      //   items: [
      //     {
      //       title: 'Kullanıcılar',
      //       path: paths.dashboard.members.root,
      //       icon: ICONS.user,
      //       children: [
      //         { title: 'Kullanıcılar', path: paths.dashboard.members.root},
      //       ],
      //     },
      //   ],
      // },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      // {
      //   subheader: 'management',
      //   items: [
      //     {
      //       title: 'user',
      //       path: paths.dashboard.group.root,
      //       icon: ICONS.user,
      //       children: [
      //         { title: 'four', path: paths.dashboard.group.root },
      //         { title: 'five', path: paths.dashboard.group.five },
      //         { title: 'six', path: paths.dashboard.group.six },
      //       ],
      //     },
      //   ],
      // },
    ],
    []
  );

  return data;
}
